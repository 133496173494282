import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import CButton from "../utils/CButton";
import CAlert from "../utils/CAlert";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { uploadFile, deleteFile, cartbiUpload, cartbiSave } from "../../services/studentService";
import CInput from "../utils/CInput";
import { saveBankData } from "../../services/bankService";
import { getUserData } from "../../actions/getUserData";
import { setAppLoader } from "../../actions/setAppLoader";
import { saveFormPath } from "../../services/userService";
import CBankData from "../utils/CBankData";
import CDialogue from "../utils/CDialogue";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function BankStatement({ setTabValue, setStepperCount, userDetailId }) {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const inputRef = React.useRef(null);

    const [error, setError] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('');
    const [fileURL, setFileURL] = React.useState('')
    const [fileName, setFileName] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [bankDetails, setBankDetails] = React.useState([])
    const [fileObj, setFileObj] = React.useState([])
    const [detailId, setDetailId] = React.useState('')
    const [counter, setCounter] = React.useState(30);
    const [isStatementProcessing, setIsStatementProcessing] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isFileUpload, setIsFileUpload] = React.useState(true);
    const MAX_FILE_SIZE = 15000 // 15MB
    const message = "Your statement is under processing please try again after sometime"
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));




    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const isCurrentAddress = userDetails?.aadharDetails?.isCurrentAddress
    const userId = userData?.userId;

    const disabledButton = !fileURL

    React.useEffect(() => {
        dispatch(getUserData(userDetailId))
    }, []);

    //Show Loader while fetching API
    React.useEffect(() => {
        dispatch(setAppLoader(isFetching))
    }, [isFetching])

    // Count for 30 second if data is not processed 
    React.useEffect(() => {
        if (isStatementProcessing) {
            dispatch(setAppLoader(true))
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }
        if (counter === 0) {
            dispatch(setAppLoader(false))
            setIsStatementProcessing(false)
            onSubmit()
            //setIsOpen(true)
        }
    }, [counter, isStatementProcessing]);

    // Call bank API after every 10 second
    React.useEffect(() => {
        if (isStatementProcessing) {
            let res = ''
            let interval = setInterval(async () => {
                res = await cartbiSave(userDetails?.detailId)
            }, 10000);
            if (res && res?.status !== "In Progress") {
                clearInterval(interval);
                setBankDetails(res?.data?.data[0])
                dispatch(setAppLoader(false))
            }
            if (counter === 0) {
                clearInterval(interval);
            }
        }
    }, [isStatementProcessing]);


    // SetData if there
    React.useEffect(() => {
        if (userDetails && userDetails?.userBankStatementDetails) {
            const bankStatement = userDetails?.userBankStatementDetails;
            setFileURL(bankStatement?.statementDetailPathId)
            setPassword(bankStatement?.statementDetailPassword)
            const fName = bankStatement?.statementDetailPathId?.split('/')
            setFileName(fName && fName[4])
            setDetailId(bankStatement?.statementDetailId)
        }
    }, [userDetails])

    function onFileUpload() {
        // 👇️ open file input box on click of other element
        inputRef.current.click();
    };


    // On File Upload
    function handleFileChange(event) {
        const fileObj = event.target.files && event.target.files[0];
        const extension = fileObj.name.split('.')[1]
        if (extension !== 'pdf' && extension !== 'PDF') {
            setError(true)
            setErrorMsg("Only PDF files are allowed")
            return;
        }
        setFileObj(fileObj)
        setIsFileUpload(false)
        setFileName(fileObj?.name)
    }

    async function handleUploadFile() {
        dispatch(setAppLoader(true))
        const formData = new FormData();
        formData.append("file", fileObj);
        formData.append("delimeter", userData?.userName)
        formData.append("detailId", +localStorage.getItem('detailId'))
        await uploadFile(formData).then(res => {
            if (res?.status === 200) {
                setFileName(fileObj?.name)
                setFileURL(res?.data)
            }
        }).catch(err => {
            setError(true)
            setErrorMsg("File length should be less than 15Mb")
        })
        const cartBiFormData = new FormData();
        cartBiFormData.append("file", fileObj);
        formData.append("id", +localStorage.getItem('detailId'))
        formData.append("password", password)

        await cartbiUpload(formData).then(res => {
            if (res?.status === 200) {
                setIsFileUpload(true)
                setDetailId(res?.data?.statementDetailId)
            }
        }).catch(err => {
            setError(true)
            setErrorMsg("File length should be less than 15Mb")
        })
        dispatch(setAppLoader(false))
    }
    // Delete uploaded file by fileURL
    async function deleteFileByURL() {
        await deleteFile(fileURL)
        setFileURL('')
        setFileName('')
        setIsFileUpload(false)
    }


    // On submit 
    async function onSubmitBankStatement(e) {
        e.preventDefault();
        setError(false)
        setErrorMsg('')
        const obj = {
            statementDetailId: detailId,
            statementDetailPathId: fileURL,
            statementDetailPassword: password ? password : null,
            userInstituteDetailId: userDetails?.detailId,
            statementDetailCreatedBy: userDetails?.user?.userName
        }
        const successBankData = await saveBankData(obj)
        const bankData = await cartbiSave(userDetails?.detailId)

        // if bank data is under processs it will return from here 
        if (bankData.status === 200) {
            if (bankData?.data?.status === "In Progress") {
                setIsStatementProcessing(true)
                return;
            } else if (bankData?.data?.statementAge > 30) {
                setError(true)
                setErrorMsg('Statement can not be older than 30 Days. Please upload a recent statement')
                return
            } else {
                setBankDetails(bankData?.data?.data[0])
            }
        } else {
            const errorMessage = bankData?.response?.data?.message
            setError(true)
            setErrorMsg(errorMessage)
            return;
        }
        if (successBankData.status === 200 || successBankData.status === 201) {
            setErrorMsg("Data Saved Successfully")
            dispatch(getUserData(userDetailId))
        } else {
            setError(true)
            setErrorMsg(successBankData?.response?.data?.message)
        }

    }

    async function onSubmit() {
        await saveFormPath("/faceMatch", userDetails?.detailId)
        setTabValue(9);
        setStepperCount(6);
        navigate("/faceMatch", { state: { tabValue: 9, stepperCount: 6, userDetailId: userDetailId } })
    }

    function handleBack() {
        isCurrentAddress ? navigate("/aadhaarDetails", { state: { tabValue: 6, stepperCount: 5, userDetailId: userDetailId } }) : navigate("/addNewAddress", { state: { tabValue: 7, stepperCount: 5, userDetailId: userDetailId } })
    }

    function isConfirm() {
        localStorage.clear();
        window.location.href = '/welcome';
    }
    return (

        <Box sx={{ position: "relative" }}>
            {
                !Object.keys(bankDetails)?.length ?
                    <form onSubmit={(e) => onSubmitBankStatement(e)} className='applicationFormContainer' >
                        {
                            error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
                        }
                        {
                            errorMsg === "Data Saved Successfully" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
                        }
                        {
                            !matches &&
                            <Box sx={{ display: "flex"}}>
                                 <ArrowBackIosIcon onClick={handleBack} />
                            </Box>
                        }
                        <Grid item xs={12} >
                            {matches && <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Kindly Upload Bank Statement</Typography>
                                // <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "center" }}>Bank Statement</Typography>
                                }
                        </Grid>
                        <Grid item sx={{ textAlign: "left" }} xs={12} >
                            <Typography color='textColor' variant='Label20' sx={{ mt: 6, textAlign: "left" }}>Please upload last 6 months statement of yout primary bank account</Typography>
                        </Grid>
                        <Grid item xs={6} container sx={{ pt: 4 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                            {matches ? <Grid sx={{ textAlign: "left" }} item xs={6}>
                                <input accept="application/pdf" type="file" style={{ display: 'none' }} onChange={handleFileChange} ref={inputRef} />
                                <Typography color="inputLabelColor" sx={{ textAlign: "left", fontWeight: "bold", fontFamily: "lato" }} align='left' variant="inputLabel">
                                    Statement
                                </Typography>
                                <Button onClick={onFileUpload} fullWidth color="uploadButton" variant="contained" startIcon={<FileUploadIcon />}>
                                    Select File
                                </Button>
                            </Grid> :
                                <Grid sx={{ textAlign: "left" }} item xs={12}>
                                    <input accept="application/pdf" type="file" style={{ display: 'none' }} onChange={handleFileChange} ref={inputRef} />
                                    <Button onClick={onFileUpload} fullWidth color="uploadButton" variant="contained" startIcon={<FileUploadIcon />}>
                                        Upload
                                    </Button>
                                </Grid>
                            }
                            {
                                matches && <Grid item md={6} xs={12}>
                                    <CInput value={password} onChange={setPassword} label="Password (if applicable)" />
                                </Grid>
                            }

                            <Grid sx={{ display: "flex", alignItems: "center", gap: "5px" }} item xs={12} md={6}>
                                {fileName &&
                                    <>
                                        <Typography color='textColor' variant='body2' sx={{ textAlign: "left" }}>{fileName}</Typography>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={deleteFileByURL}
                                            aria-label="close"
                                        >
                                            <CloseIcon color="warning" />
                                        </IconButton>
                                    </>
                                }
                            </Grid>

                            {
                                !matches && <Grid item xs={6} >

                                </Grid>
                            }
                            <Grid sx={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "flex-end" }} item xs={6}>
                                <CButton disabled={isFileUpload} handleClick={handleUploadFile} name="Upload File" />
                            </Grid>

                            <Grid>
                                <Box sx={{ textAlign: "left", fontSize: "18px", bgcolor: '#DBEEFF', color: '#4D4948', p: 2, mt: 3 }}>
                                    Do not upload scanned PDFs. Do not upload PDFs larger than 15MB. Enter the password to the PDF if applicable.
                                </Box>
                            </Grid>
                            {
                                !matches && <Grid item md={6} xs={12}>
                                    <CInput value={password} onChange={setPassword} label="Password (if applicable)" />
                                </Grid>
                            }
                        </Grid>
                        <div style={{ padding: "20px 0px 0px", display: "flex", flexDirection: "row", justifyContent: `${!matches ? "space-around" : "space-between"}` }}>
                            {matches && <CButton handleClick={() => handleBack()} name="Back" />}
                            <Button
                                type="submit"
                                sx={{ fontStyle: "Lato" }}
                                color="neutral"
                                disabled={disabledButton}
                                variant="contained">
                                Save & Submit
                            </Button>
                        </div>
                    </form > :
                    <div>
                        {
                            !matches &&
                            <Box sx={{p:4, display: "flex" }}>
                                 <ArrowBackIosIcon onClick={() => handleBack()} />
                            </Box>
                        }

                        <Box sx={{ position: "absolute", top: "100px", width: { sm: "384px", xs: "300px" }, left: { sm: "25%", xs: "12%" }, right: { sm: "0%", xs: "12%" } }}>
                            <CBankData onSubmit={onSubmit} bankDetails={bankDetails} handleBack={handleBack} />
                        </Box>
                    </div>
            }
            {isOpen && <CDialogue open={isOpen} setOpen={setIsOpen} isConfirm={isConfirm} message={message} isOkButton={true} />}
        </Box >
    )
}