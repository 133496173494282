import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import '../assets/jsx/WelcomeStyle.css'
import "../assets/jsx/ApplicationForm.css";
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CssBaseline, Grid, Paper } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import FlyLogo from "../assets/images/flyhi_logo_full.svg"
import signUPImage from "../assets/images/signUP.svg"
import CStepper from '../components/utils/CStepper';
import BankStatement from '../components/forms/BankStatement';
import CMenu from '../components/utils/CMenu';
import { getDropDownData } from '../actions/getDropDownData';
import MobileViewHeader from '../components/header/MobileViewHeader';

const BankDetails = () => {
    const theme = useTheme();
    const location = useLocation();
    const dispatch = useDispatch()
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const [stepperCount, setStepperCount] = React.useState(4);
    const [tabValue, setTabValue] = React.useState(location?.state?.tabValue);
    let jwt = localStorage.getItem('jwt')
    const [step, setStep] = React.useState();
    const [isCurrentAddress, setIsCurrentAddress] = React.useState(location.state?.isCurrentAddress);


    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const term = queryParams.get("step")
        setStep(+term)
    }, [location])


    React.useEffect(() => {
        if (jwt)
            dispatch(getDropDownData())
    }, [jwt])

    function returnHome() {
        // localStorage.clear()
        // navigate('/')
    }

    return (
        <div style={{ height: "auto" }}>
            {/* <CAlert /> */}
            <AppBar sx={{ backgroundColor:theme.palette.headerColor }} position="static">
                {matches ?
                    <Container maxWidth="xl">
                        <Toolbar sx={{ display: "grid", gridAutoFlow: 'column', justifyContent: 'space-between' }} disableGutters>
                            <img style={{ marginTop: "10px", marginBottom: "10px",width: "50px", height: "50px" }} src={FlyLogo} alt="logo" />
                            <div sx={{ pt: 6, gridColumn: '3 / 5', display: { xs: 'none', md: 'block', lg: 'none' } }}>
                                <CStepper tabValue={stepperCount} />
                            </div>
                            <div onClick={returnHome} sx={{ gridColumn: '4 / 5', textAlign: "left", fontFamily: "lato", cursor: "pointer" }} variant="body1">
                                <CMenu />
                            </div>

                        </Toolbar>
                    </Container> :
                    <MobileViewHeader stepperCount={5} header="Bank Statement" nextHeader="Face Match" />
                }
            </AppBar>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />

                <Grid item xs={12} sm={6} md={6} component={Paper} >
                    <BankStatement isCurrentAddress={isCurrentAddress} setTabValue={setTabValue} setStepperCount={setStepperCount} userDetailId={location.state.userDetailId} />
                </Grid>
                <Grid
                    item
                    display={{ xs: "none", lg: "block", sm: "block" }}
                    sm={6}
                    md={6}
                    sx={{
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <img src={signUPImage} alt="signup" />
                </Grid>
            </Grid>
        </div >
    );
};
export default BankDetails;
