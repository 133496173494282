import React, { useEffect } from 'react';
import './App.css';
import Welcome from './pages/Welcome';
import ApplicationForm from './pages/ApplicationForm';
import Admin from './pages/Admin';
import UserDashboardPage from './pages/UserDashboardPage';

import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import useMultiTheme from "./custom-hooks/useMultiTheme";
import CAppLoader from './components/utils/CAppLoader';
import InstituteDetails from './pages/InstituteDetails';
import StudentDetail from './pages/StudentDetails';
import PersonalDetail from './pages/PersonalDetails';
import PanAadhaarDetails from './pages/PanAadhaarDetails';
import AadharDetails from './pages/AadharDetails';
import AddNewAddress from './pages/AddNewAddress';
import BankDetails from './pages/BankDetails';
import FaceMatchDetails from './pages/FaceMatchDetails';
import LoanStatus from './pages/LoanStatus';
import OtpLogin from './pages/OtpLogin';
import SignUp from './components/registration/SignUp';
import Analytics from './pages/Analytics';
import LoanApproved from './pages/LoanApproved';
import LoanDeclined from './pages/LoanDeclined';
import LoanAggrement from './pages/LoanAggrement';
import LoanRepayment from './pages/LoanRepayment';
import PaymentPage from './pages/PaymentPage';

import { useSelector } from 'react-redux';
import LoanApplicationCompleted from './components/forms/LoanApplicationCompleted';
import LoanCompleted from './pages/LoanCompleted';
import SelfEvaluation from './pages/SelfEvaluation';

function App() {

  const { muiTheme } = useMultiTheme();
  let urlParams = window.location.pathname;
  const [isOtherSelected, setIsOtherSelected] = React.useState(true);
  const navigate = useNavigate()


  React.useEffect(() => {
    const mobileData = localStorage.getItem('mobileData');
    const jwt = localStorage.getItem('jwt');
    if ((!mobileData || !jwt) && urlParams !== '/evaluate' && urlParams !== '/adminLogin' && urlParams !== '/welcome'&& urlParams !== '/signup') {
      var decodedStringAtoB = atob(urlParams.substring(1));
      if(decodedStringAtoB.includes("byepass=true")) {
        localStorage.setItem("byepass", true);
        window.location.href = "/signup"
      }
      else
        window.location.href = "/welcome";
    }
  }, [])


  const userDetails = useSelector((state) => state?.getUserData?.data);

  // useEffect(()=>{
  //   const mobileData = localStorage.getItem('mobileData');
  //   const jwt = localStorage.getItem('jwt');
  //   if(userDetails?.formPath==="/loanStatus" && jwt && mobileData && urlParams !== '/loanStatus'){
  //     window.location.href = "/loanStatus";
  //   }
  // },[userDetails?.formPath])

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      // if (userDetails?.userFormStatus === "Approved") {
      //   navigate("/LoanApproved", { state: { tabValue: 10, stepperCount: 6 } })
      // } else if (userDetails?.userFormStatus === "Rejected") {
      //   navigate("/loanDeclined", { state: { tabValue: 10, stepperCount: 6 } })
      // } else {
      //   navigate("/loanStatus", { state: { tabValue: 10, stepperCount: 6 } })
      // }
    }
    else{
      //navigate("/welcome")
    }
  }, [userDetails?.userFormStatus])
  return (
    <div className="App">
      <ThemeProvider theme={muiTheme}>
        <Routes>
          <Route path='/' element={<Welcome adminLogin={false} />}></Route>
          <Route path="/adminLogin" element={<Welcome adminLogin={true} />}></Route>
          <Route path="/welcome" element={<Welcome adminLogin={false} />}></Route>
          <Route path="/otpLogin" element={<OtpLogin />}></Route>
          <Route path="/evaluate"element={<SelfEvaluation/>}></Route>
          <Route path="/signUp" element={<SignUp />}></Route>
          <Route path="/instituteDetails" element={<InstituteDetails setIsOtherSelected={setIsOtherSelected} />}></Route>
          <Route path="/studentDetails" element={<StudentDetail isOtherSelected={isOtherSelected} />}></Route>
          <Route path="/personalDetails" element={<PersonalDetail />}></Route>
          <Route path="/panAadharDetails" element={<PanAadhaarDetails />}></Route>
          <Route path="/panAadharDetails/?requestId=:requestId&status=:status" element={<PanAadhaarDetails />}></Route>
          <Route path="/aadhaarDetails" element={<AadharDetails />}></Route>
          <Route path="/addNewAddress" element={<AddNewAddress />}></Route>
          <Route path="/bankDetails" element={<BankDetails />}></Route>
          <Route path="/faceMatch" element={<FaceMatchDetails />}></Route>
          <Route path="/loanStatus" element={<LoanStatus />}></Route>
          <Route path="/loanDeclined" element={<LoanDeclined />}></Route>
          <Route path="/loanApproved" element={<LoanApproved />}></Route>
          <Route path="/loanAgreement" element={<LoanAggrement />}></Route>
          <Route path="/loanRepayment" element={<LoanRepayment />}></Route>
          <Route path="/payment" element={<PaymentPage />}></Route>
          <Route path="/loanCompleted" element={<LoanCompleted />}></Route>
          <Route path="/applicationForm" element={<ApplicationForm />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          <Route path="/analytics" element={<Analytics />}></Route>
          <Route path="/userDashboard" element={<UserDashboardPage />}></Route>

        </Routes>

        <CAppLoader />
      </ThemeProvider>
    </div>
  );
}

export default App;
