import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import '../assets/jsx/WelcomeStyle.css'
import "../assets/jsx/ApplicationForm.css";
import { Box, Button, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import FlyLogo from "../assets/images/flyhi_logo_full.svg"
import ActiveLoans from '../components/userTable/ActiveLoans';
import ActiveApplication from '../components/userTable/ActiveApplication';

import CMenu from '../components/utils/CMenu';
import CButton from '../components/utils/CButton';
import { getUserAppList, leadUpdateAndCreateOpp, prefillUserData } from '../services/userService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAppLoader } from '../actions/setAppLoader';
import PrefillModal from '../components/forms/PrefillModal';


const UserDashboardPage = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const [activeLoans, setActiveLoans] = React.useState(null);
    const [showPrefill, setShowPrefill] = React.useState(false);
    //const [prefill, setPrefill] = React.useState(false);
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const [activeApplications, setActiveApplications] = React.useState(null);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const userId = userData?.userId;
    const username = userData?.userName

    function startNewApplication() {
        //dispatch(setAppLoader(true))
        if(activeLoans?.length > 0) {
            setShowPrefill(true)
        } else 
        navigateUser(false)
        // createLeadSqauredOpportunityData()
        // dispatch(setAppLoader(false))
        // navigate('/instituteDetails', { state: { tabValue: 2, stepperCount: 2, userDetailId: null } })

    }

    async function navigateUser(prefill) {
        dispatch(setAppLoader(true))
        await createLeadSqauredOpportunityData()
        if(prefill) {
            console.log("prefill")
           const resp = await prefillUserData(userId);
           navigate(`${resp?.formPath}`, { state: { tabValue: 2, stepperCount: 2, userDetailId: resp.detailId} })

        }
        else
            navigate('/instituteDetails', { state: { tabValue: 2, stepperCount: 2, userDetailId: null } })

        dispatch(setAppLoader(false))

    }

    async function createLeadSqauredOpportunityData() {

        var fields = [];
        // Create and push the first object
        fields.push({
            "SchemaName": "mx_Custom_1",
            "Value": "FLYHI SHIKSHA-" + username + "-" + userId
        });

        // Create and push the second object
        fields.push({
            "SchemaName": "mx_Custom_28",
            "Value": "Initial draft"
        });

        // Create and push the third object
        fields.push({
            "SchemaName": "mx_Custom_2",
            "Value": "Initial draft"
        });

        // Create and push the fourth object
        fields.push({
            "SchemaName": "Status",
            "Value": "Open"
        });

        let opp = {
            "OpportunityEventCode": 12000,
            "OpportunityNote": "OPPORTUNITY_FIELDS_SCHEMA capture api overwrite",
            "UpdateEmptyFields": true,
            "DoNotPostDuplicateActivity": true,
            "DoNotChangeOwner": true,
            "Fields": fields
        }

        var LeadDetails = [];

        // Create and push the first object
        LeadDetails.push({
            "Attribute": "Mobile",
            "Value": username
        });

        // Create and push the second object
        LeadDetails.push({
            "Attribute": "SearchBy",
            "Value": "Mobile"
        });

        let obj = {
            "LeadDetails": LeadDetails,
            "Opportunity": opp
        }
        console.log(obj)
        await leadUpdateAndCreateOpp(userId, obj)

    }

    React.useEffect(() => {

        (async () => {
            dispatch(setAppLoader(true))
            const data = await getUserAppList(userId);
            var processedPieData = []
            localStorage.setItem("eligibility", data?.eligible);
            setActiveApplications(data?.activeApplications);
            setActiveLoans(data?.activeLoans);
            dispatch(setAppLoader(false))
        })();
    }, [userId])

    return (
        <>
            {
                matches ?
                    <div style={{ height: "auto" }}>
                        <AppBar sx={{ backgroundColor: theme.palette.headerColor }} position="static">
                            <Container maxWidth="xl">
                                <Toolbar sx={{ display: "grid", gridAutoFlow: 'column', justifyContent: 'space-between' }} disableGutters>
                                    <img style={{ marginTop: "10px", marginBottom: "10px",width: "50px", height: "50px" }} src={FlyLogo} alt="logo" />

                                    <div sx={{ gridColumn: '4 / 5', textAlign: "left", fontFamily: "lato", cursor: "pointer" }} variant="body1">
                                        <CMenu />
                                    </div>
                                </Toolbar>
                            </Container>
                        </AppBar>
                        <Box sx={{ display: "flex", gap: "20px", justifyContent: "space-between", px: 4, pt: 2 }}>
                            <Typography color='textColor' variant='subtitle1' sx={{ fontWeight: "bold", textAlign: "left" }}>Dashboard</Typography>
                            <div style={{ width: "350px", padding: "4px" }}>
                                <CButton disabled={activeApplications?.length > 0} handleClick={startNewApplication} name="Start a new loan application" minWidth={true} />
                            </div>
                        </Box>
                        <Grid sx={{ px: 4, pt: 8 }} item xs={12} sm={6} md={6} >
                            <Typography color='textColor' variant='body1' sx={{ pb: 2, fontWeight: "bold", textAlign: "left" }}>Active Loans</Typography>
                            <ActiveLoans activeLoans={activeLoans} />
                        </Grid>
                        <Grid sx={{ px: 4, pt: 6 }} item xs={12} sm={6} md={6} >
                            <Typography color='textColor' variant='body1' sx={{ pb: 2, fontWeight: "bold", textAlign: "left" }}>Active Applications</Typography>
                            <ActiveApplication activeApplications={activeApplications} />
                        </Grid>
                        {showPrefill && <PrefillModal showPrefill={showPrefill} setShowPrefill={setShowPrefill} navigateUser={navigateUser} />}
                    </div > :
                    <div style={{ height: "auto" }}>
                        <AppBar sx={{ backgroundColor: theme.palette.headerColor }} position="static">
                            <Container maxWidth="xl">

                                <Toolbar sx={{ display: "grid", gridAutoFlow: 'column', justifyContent: 'space-between' }} disableGutters>
                                    <img style={{ marginTop: "10px", marginBottom: "10px",width: "50px", height: "50px" }} src={FlyLogo} alt="logo" />

                                    <div sx={{ gridColumn: '4 / 5', textAlign: "left", fontFamily: "lato", cursor: "pointer" }} variant="body1">
                                        <CMenu />
                                    </div>
                                </Toolbar>
                            </Container>
                        </AppBar>

                        <Box sx={{
                            width: "175px",
                            borderRadius: 4,
                            bgcolor: activeApplications?.length>0?"rgba(0, 0, 0, 0.12)":"#D32F2F",
                            justifyContent: "center",
                            margin: "1px auto",
                            mt: 3,
                            py:2
                           
                        }}
                            >
                                
                                <Button disabled={activeApplications?.length > 0} sx={{color:"white", fontFamily: "lato", fontWeight: "bold"}} onClick={startNewApplication}  minWidth={true} >New Loan application</Button>
                            
                            {/* <Typography sx={{color:"white", fontFamily: "lato", fontWeight: "bold"}}>New Loan application</Typography> */}

                        </Box>
                        <Typography color='textColor' variant='subtitle1' sx={{ py:2,fontWeight: "bold", textAlign: "center",fontFamily: "Lato" }}>Dashboard</Typography>

                        <Grid sx={{ px: 4 }} item xs={12} sm={6} md={6} >
                            <div style={{ width: "350px", padding: "4px" }}>
                                <CButton isLato={false} fontWeight={"Bold"} fontSize={"16px"}  variant="outlined" name="Completed Application" minWidth={true} />
                            </div>
                            <ActiveLoans activeLoans={activeLoans} activeApplications={activeApplications} />
                        </Grid>
                        <Divider sx={{ my: 3 }} />

                        <Grid sx={{ px: 4 }} item xs={12} sm={6} md={6} >
                            <div style={{ width: "350px", padding: "4px" }}>
                                <CButton isLato={false} fontWeight={"Bold"} fontSize={"16px"}  variant="outlined" name={matches?"Active Application":"Application in progress"} minWidth={true} />
                            </div>
                            <ActiveApplication activeApplications={activeApplications} />
                        </Grid>
                        {showPrefill && <PrefillModal showPrefill={showPrefill} setShowPrefill={setShowPrefill} navigateUser={navigateUser} />}
                    </div >
            }
        </>
    );
};

export default UserDashboardPage;
